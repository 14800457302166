.side_drawer{
    height: 100%;
    background: white;
    box-shadow: 1px 0px 7px rgba(0,0,0,0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 220px;
	min-height: 390px;
    z-index: 200;
    transform: translateX(-120%);
    transition: transform 0.3s ease-out;
}

.side_drawer.open {
    transform: translateX(0);
}

.side_drawer ul{
	padding-top: 40px;
    height: 100%;
    list-style: none;
    display: flex;
    flex-direction: column;
	text-align: left;
	text-align: left;
}

.side_drawer li{
    margin: 0.35rem 0;
}

.side_drawer a{
	font-family: 'Roboto', sans-serif;
	font-weight:300;
	padding-left: 15px;
    color: #616161;
    text-decoration: none;
    font-size: 18px;
}

.r_arrow {
	padding-right: 15px;
	padding-top: 5px;
	width: 5%; 
	float: right;
}

.side_drawer li.footnote {
	text-align:center;
	padding-left: 20px;
	padding-bottom: 24px;
	position: absolute;
	bottom: 0px; 
}

.side_drawer li.footnote_team {
	position: absolute;
	font-size:10px;
    color: #616161;
	bottom: 0px; 
	padding-left: 90px;
	float: none;
}

.side_drawer a.footnote_info {
	font-size: 12px;
}

@media (min-width: 811){
    .side_drawer{
        display: none;
    }
}

@media (max-width: 810){
    .side_drawer{
		width: 250px;
    }
}