.toggle_button{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 20px;
    width: 20px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    box-sizing: border-box;
}

.toggle_button:focus{
    outline:none
}

.toggle_button_line{
    width: 20px;
    height: 2px;
    background: white;
}
