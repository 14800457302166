.toolbar{
    position: fixed;
    width: 100%;
    height: 56px;
    background-color: #556CB3;
    top: 0;
    left: 0;
	z-index:9999;
}

.toolbar_navigation {
    display:flex;
	max-width: 880px;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    height: 100%;
    padding: 0 1rem;
}

.toolbar_logo a:hover{
    color: white;
    text-decoration: none;
    font-size: 20px;
}

.toolbar_logo a{
    color: white;
    text-decoration: none;
	font-size: 20px;
	float:left;
	margin-top: 7px;
}

.spacer {
    flex: 1;
}

.toolbar_navigation_items {
	max-width: 750px;
    margin-left: auto;
    margin-right: auto;
    list-style: none;
    display: flex;
	text-align: center;
	vertical-align: bottom;
	line-height: 15px;
}

.toolbar_navigation_items ul{
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
	vertical-align: middle;
}

.toolbar_navigation_items li{
    padding: 0 0.5rem;
	vertical-align: middle;
}

.toolbar_navigation_items a{
    color:white;
	margin-left: -1px;
	font-size: 15px;
    text-decoration: none;
	vertical-align: middle;
}

.toolbar_navigation_items a:hover,
.toolbar_navigation_items a:active{
    color: white;
}

.logo_icon {
	float: left;
	margin-top: -10px;
}

.login_icon{
    transform: scale(1);
	margin-top: -3px;
}

@media (max-width: 755px){
    .toolbar_navigation_items{
        display: none;
    }
	
	.logo_icon {
		margin-left: 10px;
		margin-right: 8px;
		float: left;
		max-width:100%;
		height:30px;
	}
	
}

@media (min-width: 756px){
    .toolbar_toggle_button{
        display: none;
    }
    .toolbar_logo{
        margin-left: 0;
    }
	
	.logo_icon {
		float: left;
		max-width:100%;
		margin-right: 8px;
		margin-bottom: 8px;
		height:30px;
	}	


}