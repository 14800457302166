* {
	margin: 0;
	padding: 0;
}

.my-legend .legend-title {
    text-align: left;
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 90%;
    }
.my-legend .legend-scale ul {
	margin: 0;
	margin-bottom: 5px;
	padding: 0;
	list-style: none;
}
.my-legend .legend-scale ul li {
	font-size: 80%;
	list-style: none;
	margin-left: 0;
	line-height: 18px;
	margin-bottom: 2px;
}
.my-legend ul.legend-labels li span {
	display: block;
	float: left;
	height: 16px;
	width: 30px;
	margin-right: 5px;
	margin-left: 0;
	border: 1px solid #999;
}

body{
	background-color:#eeeeee;
	font-family: 'Roboto', sans-serif;
}

.App {
	font-family: 'Roboto', sans-serif;
	color: #222222;
}

.app-header {
	max-width: 100%;
	background-color: #556CB3;
	padding-left: 20px;
	padding-right: 20px;
}

.app-header h1 {
	font-family: 'Roboto', sans-serif;
	font-weight:400;
    max-width: 810px;
    margin-left: auto;
    margin-right: auto;
	padding-left: 20px;
	font-size: 18px;
	color: #FFF;
	line-height:44px;
}

.app-header h1 a {
	font-family: 'Roboto', sans-serif;
	font-size: 20px;
	color: white;
	line-height:44px;
}

.wrapper_home {
    max-width: 810px;
	height: 100%;
    margin-left: auto;
    margin-right: auto;
	margin-top: 40px;
	border-radius: 5px;
	background-color: #FFF;
	padding-right: 10px;
	padding-left: 10px;
	padding-bottom: 40px;
	border: 1px solid #e0e0e0;
}

.wrapper_home h1 {
	padding-top: 10px;
}

.wrapper_home h2 {
	padding: 80px 30px 20px;
	font-family: 'Roboto', sans-serif;
	font-weight:400;
	font-size: 32px;
	color: #111;
	line-height:44px;
	text-align: left;
}

.wrapper_home h3 {
	font-weight:300;
	font-size: 18px;
	color: #111;
	line-height:34px;
	text-align: center;
	margin-bottom: 8px;
}

.wrapper_home h6 {
	font-weight:300;
	font-size: 10px;
	color: #111;
	text-align: left;
	margin-left: 15px;
	margin-bottom: 20px;
	padding-top: 15px;
}

.wrapper_home h5 {
	font-weight:400;
	font-size: 20px;
	color: #111;
	text-align: left;
	margin-left: 15px;
	margin-bottom: 20px;
	padding-top: 25px;
}

.wrapper_home p {
}

.wrapper_home p {
	font-family: 'Roboto', sans-serif;
	font-weight:300;
	padding: 5px 0px 5px;
	font-size: 16px;
	color: #616161;
	line-height:24px;
	text-align: justify;
	margin-bottom: 40px;
}

.wrapper_home a {
	font-size:16px;
	margin-top: 0px;
	margin-left: 30px;
	text-decoration: none;
	float: left;
}

.wrapper {
    max-width: 810px;
	max-height: 100%;
    margin-left: auto;
    margin-right: auto;
	margin-top: 40px;
	border-radius: 5px;
	background-color: #eeeeee;
	padding-right: 10px;
	padding-left: 10px;
	padding-bottom: 40px;
}


.wrapper h2 {
	padding: 120px 30px 20px;
	font-family: 'Roboto', sans-serif;
	font-weight:400;
	font-size: 32px;
	color: #111;
	line-height:44px;
	text-align: left;
}

.wrapper p {
	font-family: 'Roboto', sans-serif;
	font-weight:300;
	padding: 5px 0px 10px;
	font-size: 16px;
	color: #616161;
	line-height:24px;
	text-align: left;
}


.button-choice {
	font-family: 'Roboto', sans-serif;
	font-weight:400;
	cursor: pointer;
	background: #3AD5C1;
	font-size: 20px;
	border-radius: 5px;
	color: white;
	border: 2px solid #3AD5C1;
	margin-top: 10px;
	padding: 10px 0px 10px;
	width: 220px;
	transition: 0.5s all ease-out;
	text-align: center;
}

.button-choice:hover {
	background: #1fae9b;
	border: 2px solid #1fae9b;
}

.button-start {
	font-family: 'Roboto', sans-serif;
	font-weight:400;
	cursor: pointer;
	background: #3AD5C1;
	font-size: 20px;
	border-radius: 5px;
	color: white;
	border: 2px solid #3AD5C1;
	margin-top: 10px;
	padding: 10px 0px 10px;
	width: 220px;
	transition: 0.5s all ease-out;
	text-align: center;
}

.button-start:hover {
	background: #1fae9b;
	border: 2px solid #1fae9b;
}

.checkup_image {
	padding-bottom: 5%;
	padding-top: 30px;
	padding-right: 15px;
	float: right;
}

.home_img {
	float: right;
	margin-top: 130px;
	margin-right: 24px;
	margin-left: 24px;
	padding-bottom:10px;
	height: 100%;
	width: 270px;
}

.intro_img {
	float: none;
	margin-top: 20px;
	margin-right: 30px;
	height: 100%;
	width: 270px;
}

.terms_img {
	float: left;
	height: 100%;
	width: 110px;
	margin-top: 12px;
	margin-left: 10px;
	margin-right: 10px;
	padding-bottom: 15px;
}

.terms_img2 {
	float: left;
	height: 100%;
	width: 110px;
	margin-top: 15px;
	margin-left: 10px;
	margin-right: 10px;
	padding-bottom: 5px;
}

.terms_condition {
	font-weight: 300;
	font-size: 14px;
	margin-left: 8px;
	color: #616161;
}

input[type=checkbox]{
	transform: scale(1.5);
}

.wip_img {
	margin-top: 60px;
	margin-right: 30px;
	height: 100%;
	width: 600px;
}

.wrapper a {
	font-size:16px;
	margin-top: 30px;
	margin-left: 30px;
	text-decoration: none;
	float: left;
}

.wrapper h3 {
	text-align: center;
	margin-top: 16px;
	font-family: 'Roboto', sans-serif;
	font-weight:400;
	font-size: 20px;
	color: #111;
	line-height:24px;
}


.wrapper h5 {
	text-align: left;
	margin-left: 30px;
	padding-top: 20px;
	padding-bottom: 10px;
	font-weight:400;
	font-size: 18px;
}

.custom-file-upload {
    border: 1px solid #ccc;
	border-radius: 5px;
	height: 30px;
	width: 180px;
    display: inline-block;
    padding: 6px 12px;
	padding-top: 15px;
}

.dialog_window {
	border-radius: 10px;
	margin: 20px;
	line-height: 24px;
	padding-bottom: 20px;
}

.dialog_window h6 {
	font-weight: 400;
	font-size: 18px;
	text-align: center;
	margin-bottom: 10px;	
}

.dialog_window p {
	text-indent: 20px;
	font-weight: 300;
	font-size: 15px;
	margin-bottom: 8px;
	text-align: justify;
}

.dialog_window_home {
	border-radius: 10px;
	margin: 20px;
	line-height: 24px;
	padding-bottom: 20px;
	
}


.dialog_window_home p {
	text-indent: 40px;
	font-weight: 300;
	font-size: 15px;
	margin-bottom: 8px;
	text-align: justify;
}

.dialog_window h7 {
	font-weight: 400;
	font-size: 15px;
	text-align: left;
	padding-bottom: 18px;	
}

.link_this {
	color: #556CB3;
	cursor: pointer;
}

.file_input_container {
	width: 100%;
	height: 60px;
}

.float_left {
	font-weight: 300;
	font-size: 16px;
	width: 400px;
	float:left;
	text-align: right;
	margin-bottom: 10px;
	margin-top: 10px;
	margin-left: 50px;
}

.float_right {
	float:right;
	margin-bottom: 10px;
	margin-right: 120px;
}

.footnote {
	float: left;
}

.return-home {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: -30px;
}

.button-home {
	font-family: 'Roboto', sans-serif;
	font-weight:400;
	cursor: pointer;
	background: #3AD5C1;
	font-size: 20px;
	border-radius: 5px;
	color: white;
	border: 2px solid #3AD5C1;
    position:relative;
	margin-top: 10px;
	margin-left: auto;
	margin-right: auto;
	padding: 10px 0px 10px;
	width: 140px;
	transition: 0.5s all ease-out;
	text-align: center;
}

.button-home:hover {
	background: #1fae9b;
	border: 2px solid #1fae9b;
}

.button-generate {
	font-family: 'Roboto', sans-serif;
	font-weight:400;
	cursor: pointer;
	background: #3AD5C1;
	font-size: 20px;
	border-radius: 5px;
	color: white;
	border: 2px solid #3AD5C1;
    position:relative;
	margin-top: 10px;
	margin-left: auto;
	margin-right: auto;
	padding: 10px 0px 10px;
	width: 190px;
	transition: 0.5s all ease-out;
	text-align: center;
}

.button-generate:hover {
	background: #1fae9b;
	border: 2px solid #1fae9b;
}

.heatmap_wrapper {
	background-color: white;
	padding-top: 10px;
}

.legend_img {
	margin-top: 5px;
	margin-bottom: 5px;
	width: 720px;
	border: 1px solid #eee;
	border-radius: 5px;
}

.pie-chart-cv {
	margin: 20px;
}

.heatmap-options {
	margin-left: auto;
	margin-right: auto;
}

.cc-title {
	padding-left: 5px;
	padding-bottom: 8px;
	padding-top: 0px;
    font-size: 0.8vw;
}

.cc-content {
	padding-bottom: 1px;
	padding-top: 1px;
    font-size: 5.0vw;
	font-weight: 600;
}

.cc-content-date {
    font-size: 3.4vw;
	font-weight: 600;
}

.cc-content-time {
    font-size: 1.5vw;
	font-weight: 300;
}

.cc-svg-ico {
    transform: scale(0.9);
	padding-top: 0px;
	padding-right: 3px;
	padding-left: 0px;
}

.cc-container {
    height: 21.3vh;
}

.cc-graph-container {
    height: 25vh;
	position: relative;
}

.cc-bargraph-container {
    height: 20vh;
	position: relative;
}

.summary-results {
	text-align: left;
	padding-left: 40px;
	padding-right: 50px;
}

.summary-results ul li {
	padding-bottom: 10px;
}

.svg-bullet {
	transform: scale(0.9);
	padding-top: 0px;
	padding-right: 10px;
	padding-left: 0px;
}

.summary-table {
	padding: 5px;
}

.help_img {
	margin-top: 10px;
	border-radius: 5px;
	width: 95%;
}

.wrapper_help {
    max-width: 1000px;
	height: 100%;
    margin-left: auto;
    margin-right: auto;
	margin-top: 40px;
	border-radius: 5px;
	background-color: #fff;
	padding-right: 10px;
	padding-left: 10px;
	padding-bottom: 40px;
	border: 1px solid #e0e0e0;
}

.wrapper_help p {
	font-family: 'Roboto', sans-serif;
	font-weight:300;
	padding: 5px 0px 5px;
	font-size: 16px;
	color: #000;
	line-height:24px;
	text-align: left;
	margin-bottom: 40px;
	margin-left: 5px;
	margin-right: 5px;
}

.videos-help {
	width: 95%; 
    height: auto; 
    margin: auto;
	padding: 0px;
	text-align: left;
}

.videos-help p {
	text-align: justify;
}

.help-footnote {
	font-weight: 300;
	font-size: 13px;
	text-align: justify;
}

.img-help {
	width: 80%;
}

.legend_carousel {
	background-color: white;
	opacity: 0.3;
	margin: 5px;
	border-radius: 3px;
	vertical-align: center;
}

.legend_carousel:hover {
	opacity: 0.5;
}

.videos-table {
	margin: auto;
	width: 100%;
    table-layout: fixed;
}

.videos-table tr, td {
	padding: 5px;
}

.btn-vid-1 {
	max-width: 480px;
	max-height: 252px;
    width: 100vw;
    height: 36.4vw;
	background-image: url('thumbnails/register_vid.png'); 
    background-size: 100%; 
	background-repeat: no-repeat;
	border: none;
	border: 1px solid #eee;
}

.btn-vid-1:hover {
	cursor: pointer;
}

.btn-vid-2 {
	max-width: 480px;
	max-height: 252px;
    width: 100vw;
    height: 36.4vw;
	background-image: url('thumbnails/sendupdate_vid.png'); 
    background-size: 100%; 
	background-repeat: no-repeat;
	border: 1px solid #eee;
}

.btn-vid-2:hover {
	cursor: pointer;
}

.btn-vid-3 {
	max-width: 480px;
	max-height: 252px;
    width: 100vw;
    height: 36.4vw;
	background-image: url('thumbnails/clearance_vid.png'); 
    background-size: 100%; 
	background-repeat: no-repeat;
	border: none;
	border: 1px solid #eee;
}

.btn-vid-3:hover {
	cursor: pointer;
}

.btn-vid-4 {
	max-width: 480px;
	max-height: 252px;
    width: 100vw;
    height: 36.4vw;
	background-image: url('thumbnails/password_vid.png'); 
    background-size: 100%; 
	background-repeat: no-repeat;
	border: none;
	border: 1px solid #eee;
}

.btn-vid-4:hover {
	cursor: pointer;
}

.caption-left {
	text-align: justify;
	padding-left: 12px;
	padding-right: 12px;
}

.bottom-nav {
	bottom: 20px;
	width: 100%;
	text-align: center;
}

.bottom-nav a{
	margin-left: 0px;
	margin-top: 10px;
	width: 100%;
	padding: 0px;
}

.home-btn {
	font-family: 'Roboto', sans-serif;
	font-weight:400;
	cursor: pointer;
	background: #3AD5C1;
	font-size: 18px;
	border-radius: 5px;
	color: white;
	border: 2px solid #3AD5C1;
	margin-top: 7px;
	padding: 15px 0px 15px;
	width: 75%;
	transition: 0.5s all ease-out;
	text-align: center;
}

@media (min-width: 811px){	
	.button-choice-right {
		margin-right: 20px;
		margin-top: 10px;
		padding: 10px 0px 10px;
	}
	
	.button-choice {
		margin-top: 20px;
	}	
	
	.button-start {
		margin-top: 20px;
		margin-left: -180px;
	}
	
	.nav {
		margin-top: 30px;
	}
	
	.wrapper p {
		padding-left: 30px;
		padding-right: 20px;
	}
	
	.wrapper {
		min-height: 400px;
	}
	.wrapper_home p {
		padding-left: 30px;
		padding-right: 20px;
	}
	
	.bottom-nav {
		display: none;
	}
	
}

@media (max-width: 810px){
	body{
		background-color:#fff;
	}
	
	.wrapper {	
		background-color:#fff;
		padding-left: 0px;
	}
	
    .wrapper h2 {
		padding: 5px 30px 0px;
		font-family: 'Roboto', sans-serif;
		font-weight:400;
		font-size: 24px;
		color: #111;
		line-height:44px;
		text-align: left;
	}
	
	.wrapper_home {
		border: 0px;
		padding-left: 0px;
	}

		
	.wrapper_home h2 {
		padding: 5px 30px 0px;
		font-family: 'Roboto', sans-serif;
		font-weight:400;
		font-size: 24px;
		color: #111;
		line-height:44px;
		text-align: left;
	}
	
	.wrapper_home h3 {
		font-size: 17px;
		margin-bottom: 2px;
		line-height: 20px;
	}
	
	.wrapper_home h6 {
		text-align: left;
		margin-left: 0px;
		margin-bottom: 20px;
		padding-top: 15px;
	}
	
	.wrapper_home p {
		padding-left: 30px;
		padding-right: 20px;
		padding-bottom: 0px;
	}
	
	.wrapper h3 {
		padding-top: 5px;
	}
	
	.wrapper h4 {
		padding-top: 8px;
		margin-left: 10px;
	}

	.form-choice{
		text-align: center;
		margin-left: -15px;
	}

    .checkup_image {
		display: block;
		margin-left: auto;
		margin-right: auto;
		float: none;
	}
	
	.logo {
		display: block;
		margin-left: auto;
		margin-right: auto;
		margin-top: 30px;
		float: none;
		max-width:100%;
		height:110px;
	}
	
	.home_img {
		float: none;
		margin-top: 10px;
		margin-right: 0px;
		margin-left: 2px;
		margin-bottom: 10px;
		height: 100%;
		width: 180px;;
	}
	
	.intro_img {
		float: none;
		margin-top: 10px;
		margin-right: 0px;
		margin-bottom: 10px;
		height: 100%;
		width: 220px;;
	}
	
	.wip_img {
		height: 100%;
		width: 300px;
	}
	
	.terms_img {
		display: none;
	}
	
	.terms_img2 {
		display: none;
	}
	
	.wrapper a {
		margin-left: 50px;
		margin-top: 20px;
	}
	
	.wrapper p {
		padding-left: 30px;
		padding-right: 20px;
		padding-bottom: 0px;
	}
	
	.button-choice {
		width: 190px;
		transition: 0.5s all ease-out;
		text-align: center;
	}
	
	.button-choice-right {
		margin-right: 20px;
		margin-top: 20px;
		padding: 10px 0px 10px;
	}
	
	.dialog_window p {
		text-align: justify;
	}
	
	.file_input_container {
		height: 120px;
	}
	
	.float_left {
		width: 350px;
		float:none;
		margin-left: auto;
		margin-right: auto;
		text-align: center;
	}

	.float_right {
		float:none;
		margin-left: auto;
		margin-right: auto;
	}
	
	.button-home {
		margin-top: 80px;
		margin-left: auto;
		margin-right: auto;
	}
	
	.legend_img {
		width: 420px;
	}
	
	.heatmap-options {
		width: 300px;
	}
	
	dashboard-minWidth {
		display: true;
	}
	
	dashboard-maxWidth {
		display: none;
	}
	
		
	.cc-title {
		font-size: 100%;
	}
	
	.cc-content {
		font-size: 100px;
	}
	
	.cc-content-date {
		font-size: 100px;
	}

	.cc-content-time {
		font-size: 2.7vh;
	}
	
	.videos-help p {
		font-size: 14px;
		line-height: 20px;
		text-align: left;
	}
	
	.videos-help {
		width: 100%; 
		height: auto; 
		margin: auto;
		padding: 0px;
	}
	
	.help_img {
		width: 100%;
	}

	.help-footnote {
		text-align: justify;
	}
	
	.legend_carousel {
		display: none;
	}
	
	.wrapper_help {
		border: 0px;
	}
	
	.video-size {
		padding-bottom: 10px;
		padding-top: 10px;
	}
	
	.videos-table {
		width: 100%;
	}
	
	.videos-table tr, td {
		padding-top: 10px;
		padding-left: 0px;
		padding-right: 0px;
	}
	
	.img-help {
		width: 100%;
	}
	
	.btn-vid-1 {
		width: 80vw;
		height: 43.5vw;
	}

	.btn-vid-2 {
		width: 80vw;
		height: 43.5vw;
	}

	.btn-vid-3 {
		width: 80vw;
		height: 43.5vw;
	}

	.btn-vid-4 {
		width: 80vw;
		height: 43.5vw;
	}
	
	.caption-left {
		padding-left: 30px;
		padding-right: 30px;
	}
	
	.normal-nav {
		display: none;
	}
	
	.footnote {
		padding-left: 15px;
	}
	
	.bottom-nav h2 {
		margin-left: -30px;
		font-size: 32px;
		padding-bottom: 10px;
	}

}