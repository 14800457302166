* {
	margin: 0;
	padding: 0;
}

.form_container {
    max-width: 90%;
	height: 100%;
	text-align: left;
    margin-left: auto;
    margin-right: auto;
	margin-top: 40px;
	background-color: #FFF;
	padding-right: 10px;
	padding-left: 10px;
	padding-bottom: 40px;
}

.form_checkbox {
    width: 100%;
}

.adPatient-table Table tr h9 {
	border: 1px solid blue;
	vertical-align: center;
	padding-right: 10px;
}


Table {
	vertical-align: center;
}

.adPatient-table {
	table-layout: fixed;
	width: 100%;
}

.adPatient-list-table {
	border: 1px solid #4C6FEE;
	table-layout: fixed;
	border-radius: 5px;
	width: 100%;
	height: 100%;
}

.admission-symptoms-table {
	border: 1px solid #4C6FEE;
	table-layout: fixed;
	border-radius: 5px;
	width: 100%;
	height: 100%;
}

.adPatient-guardian-table {
	table-layout: fixed;
	width: 75%;
}

.adPatient-half-table {
	table-layout: fixed;
	width: 50%;
}

.adPatient-twochoice-table {
	width: 40%;
}

.align-right {
	text-align: right;
}

.pie-chart {
	padding: 10px;
	padding-top: 15px;
	margin-left: auto;
	margin-right: auto;
	position: relative;
}

.svg_icons{
    transform: scale(1.2);
	padding-top: 8px;
	padding-right: 5px;
	padding-left: 1px;
}

.card-label{
	color: #4C6FEE;
}

.dashboard-table {
	table-layout: fixed;
	width: 100%;
    padding: 5px;
}

h4 {
	font-size: 20px;
}

.dashboard-table tr td{
    padding: 10px;
}

.dashboard-img {
	width: 50%;
	padding: 1px;
}

.leftbox { 
	float:left;
	width:40%; 
	
} 

.rightbox { 
	text-align: right;
    display:inline;
	padding-right: 20px;
	width:40%; 
} 

.dashboard-maxWidth {
	align: center;
}

.dashboard-minWidth {
	padding-left: 10px;
	
}

.minWidth-left {
	text-align: right;
	width: 50%;
	float: left;
}

.minWidth-right{
	text-align: left;
}

.bg {
	background-color: #eeeeee;
}

.fill-white {
	background-color: #fff;
}

.cc-dashboard-img {
	width: 90%;
	padding-left: 30px;
}

.right-align {
	text-align: right;
}
	
.left-align-text {
	text-align: left;
}

.left-align {
	text-align: left;
	width: 300px;
}

.inside-table {
	width: 100%;
}

.cc-dashboard-table {
	margin-right: auto;
	margin-left: auto;
	table-layout: fixed;
	width: 98%;
	height: 100px;
	border-spacing: 10px;
}

.dashboard-heatmap {
	padding: 5px;
}

.items-div {
	padding-bottom: 5px;
	table-layout: fixed;
	height: 100%;
}

.tag-item {
	background-color: #eee;
	display: inline-block;
	font-size: 14px;
	border-radius: 30px;
	height: 30px;
	padding: 0 4px 0 1rem;
	display: inline-flex;
	align-items: center;
	margin: 0 0.3rem 0.3rem 0;
	
}

.tag-item > .button {
	background-color: white;
	width: 22px;
	height: 22px;
	border-radius: 50%;
	border: none;
	cursor: pointer;
	font: inherit;
	margin-left: 10px;
	font-weight: bold;
	padding: 0;
	line-height: 1;
	display: flex;
	align-items: center;
	justify-content: center;
}

.card-title {
	padding-left: 5px;
	padding-bottom: 8px;
	padding-top: 1px;
    font-size: 0.8vw;
}

.card-content {
	padding-bottom: 1px;
	padding-top: 1px;
    font-size: 4.8vw;
	font-weight: 600;
}

.card-content-date {
	padding-bottom: 1px;
	padding-top: 1px;
    font-size: 3.9vw;
	font-weight: 600;
}

.dashboard-card {
	padding-bottom: 1px;
	padding-top: 1px;
    font-size: 2vw;
	font-weight: 600;
}

.canvas-container {
    height: 21.3vh;
}

.canvas-graph-container {
    height: 25vh;
	position: relative;
}

.canvas-bargraph-container {
    height: 28vh;
	position: relative;
}

.facilities-list a:link {
    text-decoration: none;
	color: black;
}

.facilities-list a:visited {
    text-decoration: none;
	color: black;
}

.facilities-list a:active {
    text-decoration: none;
	color: #4C6FEE;
}

.facilities-list a:hover {
    text-decoration: none;
	color: #74b8e3;
}

@media (min-width: 1065px) {
	.adPatient-twochoice-table {
		width: 70%
	}
	
	.dashboard-minWidth {
		display:none;
	}
	

}

@media (max-width: 1064px){

	/* Force table to not be like tables anymore */
	.table, thead, tbody, th, td, tr { 
		width: 100%;
		display: block; 
	}
	
	/* Hide table headers (but not display: none;, for accessibility) */
	.thead tr { 
		position: absolute;
		top: -9999px;
		left: -9999px;
	}
	
	.td { 
		/* Behave  like a "row" */
		border: none;
		position: relative;
		padding-left: 50%; 
	}
	
	
	.adPatient-guardian-table {
		width: 100%;
	}
	
	.adPatient-half-table {
		width: 100%;
	}
	
	.dashboard-maxWidth {
		display: none;
	}
	
	.right-align {
		display: none;
	}
	
	.right-align-text {
		text-align: center;
	}

	.left-align {
		text-align: center;
		width: 100%;
	}
	
	.card-title {
		font-size: 100%;
	}
	
	.card-content {
		font-size: 200px;
	}
}
