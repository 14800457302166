
.checkup_image_terms {
	padding-bottom: 5%;
	padding-right: 15px;
	margin-top: 75px;
	float: right;
}

.card{
    width: 100%;
    height: 100%;
    flex: auto;
}

.button-nextchoice {
	font-family: 'Roboto', sans-serif;
	font-weight:400;
	cursor: pointer;
	background: #3AD5C1;
	font-size: 18px;
	border-radius: 5px;
	color: white;
	border: 2px solid #3AD5C1;
	margin-top: 10px;
	margin-right: 30px;
	padding: 10px 0px 10px;
	width: 120px;
	transition: 0.5s all ease-out;
	text-align: center;
	float: right;
}

.two_choice {
	padding: 0;
	margin-left: auto;
	margin-right: auto;
	width:480px;
}

.button-yeschoice {
	font-family: 'Roboto', sans-serif;
	font-weight:400;
	cursor: pointer;
	background: #3AD5C1;
	font-size: 18px;
	border-radius: 5px;
	color: white;
	border: 2px solid #3AD5C1;
	margin-top: 10px;
	margin-right: 75px;
	padding: 10px 0px 10px;
	width: 120px;
	transition: 0.5s all ease-out;
	text-align: center;
	float: right;
}

.button-nochoice {
	font-family: 'Roboto', sans-serif;
	font-weight:400;
	cursor: pointer;
	background: #3AD5C1;
	font-size: 18px;
	border-radius: 5px;
	color: white;
	border: 2px solid #3AD5C1;
	margin-top: 10px;
	margin-right: 75px;
	padding: 10px 0px 10px;
	width: 120px;
	transition: 0.5s all ease-out;
	text-align: center;
	float: right;
}

.button-backchoice {
	font-family: 'Roboto', sans-serif;
	font-weight:400;
	font-size: 16px;
	float: left;
	color: #3ad5c1;
	cursor: pointer;
	margin-top: 24px;
	margin-left: 40px;
	padding: 0px;
	width: 50px;
	text-align: center;
	border: none;
	background: none;
}

.button-return-to-home {
	font-family: 'Roboto', sans-serif;
	font-weight:400;
	cursor: pointer;
	background: #3AD5C1;
	font-size: 18px;
	border-radius: 5px;
	color: white;
	border: 2px solid #3AD5C1;
	margin-top: 10px;
	margin-right: 30px;
	padding: 10px 0px 10px;
	width: 150px;
	transition: 0.5s all ease-out;
	text-align: center;
	float: center;
}

p.content {
	font-weight: 400;
}

.button-nextchoice:hover {
	background: #1fae9b;
	border: 2px solid #1fae9b;
}

.button-nextchoice:disabled {
	background: #a6a6a6;
	border: 2px solid #a6a6a6;
	cursor: auto;
}


.button-yeschoice:hover {
	background: #1fae9b;
	border: 2px solid #1fae9b;
}

.button-nochoice:hover {
	background: #1fae9b;
	border: 2px solid #1fae9b;
}



.screen h1 {
	font-weight:300;
	margin-top: 30px;
	margin-left: 40px;
	font-size: 18px;
	text-align: left;
}
.progress_change {
	border-bottom: 1px solid #3AD5C1;
}
.screen .progress_change h2 {
	text-align: right;
	font-weight:300;
	margin-right: -10px;
	font-size: 15px;
}

.bot_nav {
	margin-top: 120px;
	padding-top: 0px;
}


.male_button{
    width: 92px;
    height: 123px;
	background-image: url('male_icon_button.png'); 
    background-size: cover; 
    float: right;
    margin-top: 30px;
    margin-right: 190px;
    border-width: 3px;
    border-color: #1fae9b;
	border-radius: 7px;
	filter: drop-shadow(2px 2px 1px gray);
}

.male_button:hover {
	cursor: pointer;
	filter: drop-shadow(0px 0px 1px gray);
}

.female_button:hover {
	cursor: pointer;
	filter: drop-shadow(0px 0px 1px gray);
}

.female_button{
    width: 92px;
    height: 123px;
	background-image: url('female_icon_button.png'); 
    background-size: cover; 
    float: left;
    margin-top: 30px;
    margin-left: 190px;
    border-width: 3px;
    border-color: #1fae9b;
	border-radius: 7px;
	filter: drop-shadow(2px 2px 1px gray);
}

.faculty_button{
    width: 92px;
    height: 123px;
	background-image: url('faculty_icon_button.png'); 
    background-size: cover; 
    margin-left: 10px;
    margin-right: 10px;
    border-width: 3px;
    border-color: #1fae9b;
	border-radius: 7px;
	filter: drop-shadow(2px 2px 1px gray);
}

.student_button{
    width: 92px;
    height: 123px;
	background-image: url('student_icon_button.png'); 
    background-size: cover; 
    margin-left: 10px;
    margin-right: 10px;
    border-width: 3px;
    border-color: #1fae9b;
	border-radius: 7px;
	filter: drop-shadow(2px 2px 1px gray);
}

.staff_button{
    width: 92px;
    height: 123px;
	background-image: url('staff_icon_button.png'); 
    background-size: cover; 
    margin-left: 10px;
    margin-right: 10px;
    border-width: 3px;
    border-color: #1fae9b;
	border-radius: 7px;
	filter: drop-shadow(2px 2px 1px gray);
}

.student_button:hover {
	cursor: pointer;
	filter: drop-shadow(0px 0px 1px gray);
}

.faculty_button:hover {
	cursor: pointer;
	filter: drop-shadow(0px 0px 1px gray);
}

.staff_button:hover {
	cursor: pointer;
	filter: drop-shadow(0px 0px 1px gray);
}


.for_select {
	width: 300px;
	margin-left: auto;
	margin-right: auto;
	text-align: left;
}

.checkbox_layout {
	text-align: left;
	width: 340px;
}

.checkbox_layout2 {
	text-align: left;
	width: 340px;
}

.checkbox_layout3 {
	text-align: left;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 0px;
	line-height: 15px;
	width: 370px;
}

.clearance-pass-box {
	width: 300px;
	margin-left:auto;
	margin-right:auto;
	padding: 10px;
	text-align: center;
	color: #FFF;
	margin-top: 30px;
}

.clearance-pass-box h3 {
	color: #FFF;
}

.clearance_img {
	margin-top: 10px;
	margin-right: 0px;
	margin-bottom: 10px;
	height: 100%;
	width: 180px;;
}

.info_img {
	margin-top: 10px;
	margin-left: 20px;
	margin-right: 20px;
	margin-bottom: 55px;
	height: 100%;
	width: 50px;
	float: left;
}

.return-clearance {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 0px;
}

.code-description {
	width: 800px;
	margin-left:auto;
	margin-right:auto;
	border-radius: 5px;
	border: 1px solid #3b3b3b;
	margin-bottom: 20px;
	margin-top: 20px;
}

.typo-divider {
	border-bottom: 1px solid #eee;
	color: #3b3b3b;
	text-align: right;
	padding-top: 20px;
	padding-bottom: 20px;
	margin-bottom: 50px;
	padding-right: 20px;
}

.user-actions {
	display: block;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	float: center;	
}

.end-container {
	border: 1px solid #3AD5C1;
	border-radius: 3px;
	width: 92%;
	margin: auto;
	text-align: justify;
	padding: 7px;
}

@media (min-width: 811px){
        
    .progress{
        float: left;
        top:0;
        left:0;
        width: 30px;
        height: 180px;
        margin-left: -7px;
        margin-top: 60px;
    }
	
	.progress_change {
		display: none;
	}
	
    .screen{
        top:0;
        right:0;
        float:right;
		margin-top: 30px;
		margin-left: auto;
		margin-right: auto;
		padding-bottom: 20px;
        width:600px;
		min-height: 200px;
		background-color: #fff;
		border-radius: 5px;
		border: 1px solid #e0e0e0;
    }
	
    .screen h1 {
		margin-left: 10px;
		font-weight: 400;
		text-align: center;
		margin-bottom: 30px;
	}
	
	.screen h3 {
		padding-top: 130px;
	}
	
    .age_slider{
        width: 400px;
        margin-left: 50px;
        margin-right: 50px;
    }
	
	.screen p {
		margin-top: 0px;
		margin-left: 0px;
	}
	
	.screen .results p {
		margin-top: 30px;
		margin-left: 40px;
	}
	
	.two_choice {
		padding-bottom: 60px;
	}

	.sex_choice {
		padding-bottom: 10px;
	}
	
	.leaflet-container{
		width: 550px;
		height: 520px;
		margin-left: 30px;
		border-radius: 5px;
		margin-bottom: -100px;
	}	
	
	.screen h4{
		text-align: center;
		margin-left: 0px;
		padding-top: 10px;
		font-weight:300;
	}

	.checkbox_layout {
		margin-left: auto;
		margin-right: auto;
		margin-bottom: -130px;
		line-height: 15px;
		width: 370px;
	}
	
	.checkbox_layout2 {
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 0px;
		line-height: 15px;
		width: 370px;
	}
	
	.wrapper_home .checkbox_layout {
		margin-left: auto;
		margin-right: auto;
		width: 370px;
	}
	
		
	.user_terms {
		margin-top: 20px;
		margin-left: 24px;
	}
}


@media (max-width: 810px){
    .progress{
        display: none;
    }

    .screen{
        top: 0;
        right: 0;
        left: 0;
        float: left;
		margin-left: 5px;
		margin-right: auto;
		margin-top: -20px;
		width: 100%;
        border: 0px;
		padding-bottom: 20px;
    }
	
	.screen h1 {
		margin-left: 10px;
		font-weight: 400;
		text-align: center;
		margin-bottom: 30px;
	}
	
	.screen h3 {
		margin-bottom: 10px;
		margin-left: 24px;
	}
	
	.checkup_image_terms {
		display: none;
	}
	
	.button-twochoice {
		width: 110px;
		margin-left: 20px;
	}
	
	.button-nextchoice {
		margin-top: 20px;
		margin-right: 20px;
		padding: 10px 0px 10px;
		float: right;
	}

	.button-backchoice {
		margin-top: 36px;
		margin-left: 30px;
		padding: 0px;
		float: left;
	}

	.button-return-to-home {
		margin-top: 36px;
		margin-left: 30px;
		padding: 0px;
		float: center;
	}

	.bot_nav {
		margin-top: 10px;
		width: 95%;
	}

	
	.screen p {
		margin-top: 8px;
		margin-left: 0px;
	}

	.two_choice {
		margin-bottom: 150px;
		width:340px;
		margin-left: auto;
		margin-right: auto;
	}
	
	.progress_change {
		width: 100%;
	}

	.button-yeschoice {
		font-family: 'Roboto', sans-serif;
		font-weight:400;
		cursor: pointer;
		background: #3AD5C1;
		font-size: 18px;
		border-radius: 5px;
		color: white;
		border: 2px solid #3AD5C1;
		margin-top: 50px;
		margin-right: 30px;
		padding: 10px 0px 10px;
		width: 120px;
		transition: 0.5s all ease-out;
		text-align: center;
		float: right;
	}

	.button-nochoice {
		font-family: 'Roboto', sans-serif;
		font-weight:400;
		cursor: pointer;
		background: #3AD5C1;
		font-size: 18px;
		border-radius: 5px;
		color: white;
		border: 2px solid #3AD5C1;
		margin-top: 50px;
		margin-right: 15px;
		padding: 10px 0px 10px;
		width: 120px;
		transition: 0.5s all ease-out;
		text-align: center;
		float: right;
	}

	.female_button{
		margin-left: 0px;
		margin-right: 15px;
	}
	
	.male_button{
		margin-right: 0px;
		margin-left: 15px;
	}
	
	.faculty_button{
		margin-left: 0px;
		margin-right: 15px;
	}
	
	.student_button{
		margin-right: 0px;
		margin-left: 0px;
	}
	
	.staff_button{
		margin-right: 15px;
		margin-left: 0px;
	}

	.sex_choice {
		display: inline-block;
	}
	
	.leaflet-container{
		max-width: 95%;
		min-width: 320px;
		margin-bottom: -34px;
		height: 410px;
		margin-left: 10px;
	
	}
	
	.screen h4{
		text-align: center;
		margin-left: 0px;
		padding-top: 10px;
		font-weight:300;
	}

	.checkbox_layout {
		margin-left: auto;
		margin-right: auto;
		font-size: 15px;
		line-height: 15px;
		font-weight: 400;
		width: 300px;
	}
	
	.checkbox_layout2 {
		margin-left: auto;
		margin-right: auto;
		font-size: 15px;
		line-height: 15px;
		font-weight: 400;
		width: 300px;
	}
	
	.user_terms {
		margin-left: 15px;
		margin-right: 5px;	
	}
	
	.code-description {
		width: 90vw;
	}
	
	.info_img {
		margin-top: 5px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 5px;
		height: 100%;
		width: 50px;
		float: none;
	}
	
	.typo-divider {
		padding-top: 10px;
		padding-bottom: 10px;
		margin-bottom: 60px;
		padding-right: 10px;
	}
	
}

.slidecontainer {
  width: 100%;
}

