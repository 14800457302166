.news{
    font-style: none;
}

.help{
    font-style: none;
}

.report{
    font-style: none;
}


.p {
	font-family: 'Roboto', sans-serif;
	font-weight:300;
	float: left;
	padding: 20px 30px 20px;
	font-size: 16px;
	color: #616161;
	line-height:24px;
}

